<!--  -->
<template>
  <div class="applyCar">
    <van-form class="main-wrapper" ref="publishNotice">
      <div class="select-box">
        <van-field
          required
          readonly
          clickable
          name="cartCode"
          v-model="platingNumber"
          label="选择车辆"
          placeholder="请选择车辆"
          @click="showCarList = true"
          right-icon="arrow"
        >
        </van-field>
        <van-popup v-model="showCarList" position="bottom">
          <van-picker
            v-if="carList && carList.length"
            show-toolbar
            :columns="carList"
            value-key="platingNumber"
            @confirm="carListConfirm"
            @cancel="showCarList = false"
          />
          <van-empty v-else description="暂无车辆" />
        </van-popup>

        <van-field
          required
          readonly
          clickable
          name="applyDate"
          v-model="form.applyDate"
          label="用车日期"
          placeholder="请选择用车日期"
          @click="showTimePicker = true"
          right-icon="arrow"
        />
        <van-popup v-model="showTimePicker" position="bottom">
          <van-datetime-picker
            v-model="currentDate"
            type="date"
            title="选择年月日"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="applyDateConfirm"
            @cancel="showCarList = false"
          />
        </van-popup>

        <van-field
          required
          readonly
          clickable
          name="beginTime"
          v-model="beginTime"
          label="开始时间"
          placeholder="请选择开始时间"
          maxlength="50"
          right-icon="arrow"
          @click="showBeginTimeDialog"
        />
        <van-popup v-model="showBeginTime" position="bottom">
          <van-picker
            show-toolbar
            :columns="timeList"
            title="选择时间"
            @confirm="beginTimeConfirm"
            @cancel="showBeginTime = false"
          >
            <template #option="option">
              <div>
                <span>{{ option.beginTime }}</span>
                <span v-if="0 == option.status">已超时</span>
                <span v-if="2 == option.status">已预定</span>
                <span v-if="3 == option.status">我的预定</span>
              </div>
            </template>
          </van-picker>
        </van-popup>

        <van-field
          required
          readonly
          clickable
          v-model="endTime"
          name="endTime"
          label="预计结束时间"
          placeholder="请选择预计结束时间"
          maxlength="50"
          @click="showEndTimeDialog"
          right-icon="arrow"
        />
        <van-popup v-model="showEndTime" position="bottom">
          <van-picker
            show-toolbar
            :columns="timeList"
            title="选择时间"
            value-key="time"
            @confirm="endTimeConfirm"
            @cancel="showEndTime = false"
          >
            <template #option="option">
              <div>
                <span>{{ option.time }}</span>
                <span v-if="0 == option.status">（已超时）</span>
                <span v-if="2 == option.status">（已预定）</span>
                <span v-if="3 == option.status">（我的预定）</span>
              </div>
            </template>
          </van-picker>
        </van-popup>

        <van-field
          v-model="form.origin"
          name="origin"
          label="去向"
          placeholder="请输入去向"
          maxlength="50"
          required
        />

        <van-field
          required
          readonly
          clickable
          name="cardType"
          v-model="cardType"
          label="借用油卡"
          placeholder="请选择是否借用油卡"
          @click="showCardType = true"
          right-icon="arrow"
        >
        </van-field>
        <van-popup v-model="showCardType" position="bottom">
          <van-picker
            show-toolbar
            :columns="carTypeList"
            @confirm="carTypeConfirm"
            @cancel="showCardType = false"
          />
        </van-popup>

        <van-field
          v-if="1 == form.cardType"
          required
          readonly
          clickable
          name="cardNo"
          v-model="cardName"
          label="选择油卡"
          placeholder="请选择油卡"
          @click="showCardNo = true"
          right-icon="arrow"
        >
        </van-field>
        <van-popup v-model="showCardNo" position="bottom">
          <van-picker
            show-toolbar
            :columns="oilList"
            value-key="cardName"
            @confirm="carNoConfirm"
            @cancel="showCardNo = false"
          />
        </van-popup>
      </div>

      <div class="card">
        <div class="sub-title"><i style="color: red">*</i> 用车事由</div>
        <textarea
          v-model="form.reason"
          cols="30"
          rows="10"
          class="text-area"
          maxlength="120"
        ></textarea>
      </div>

      <div class="card">
        <div class="sub-title">内部乘车人</div>
        <div class="tag-ul">
          <van-tag
            v-for="(item, index) in passengerList"
            :key="index"
            closeable
            size="medium"
            type="primary"
            @close="delPassenger(item, index)"
          >
            {{ item.userName }}
          </van-tag>
        </div>
        <img
          :src="addImg"
          alt=""
          class="add-img"
          @click="addPassenger"
          v-if="passengerList.length < 4"
        />
      </div>
      <!-- 部门审批人 -->
      <div class="card">
        <div class="sub-title star">部门审批人</div>
        <div class="tag-ul">
          <van-tag
            v-for="(item, index) in managerUsers"
            :key="index"
            closeable
            size="medium"
            type="primary"
            @close="delManager(item, index)"
            class="tag-li"
          >
            {{ item.userName }}
          </van-tag>
        </div>
        <img :src="addImg" alt="" class="add-img" @click="addManager" />
      </div>
      <!-- 审批人 -->
      <div class="card">
        <div class="sub-title star">审批人</div>
        <div class="tag-ul">
          <van-tag
            v-for="(item, index) in approveList"
            :key="index"
            closeable
            size="medium"
            type="primary"
            @close="delApprove(item, index)"
            class="tag-li"
          >
            {{ item.userName }}
          </van-tag>
        </div>
        <img :src="addImg" alt="" class="add-img" @click="addApprove" />
      </div>
      <div class="card">
        <div class="sub-title">抄送人</div>
        <div class="tag-ul">
          <van-tag
            v-for="(item, index) in copyList"
            :key="index"
            closeable
            size="medium"
            type="primary"
            @close="delCopy(item, index)"
            class="tag-li"
          >
            {{ item.userName }}
          </van-tag>
        </div>
        <img :src="addImg" alt="" class="add-img" @click="addCopy" />
      </div>
    </van-form>

    <div class="bottom">
      <div class="button" @click="submit">提交</div>
    </div>
    <!-- <van-popup v-model="showManager" position="bottom" round>
      <van-cell v-for="item in staffData" :key="item" :title="item">{{
        i
      }}</van-cell>
    </van-popup> -->
    <van-action-sheet
      style="min-height: 30%; max-height: 60%"
      v-model="showManager"
      title="部门负责人"
    >
      <van-cell
        v-for="(item, i) in staffData"
        :key="i"
        :class="{ disabledName: item.jobType == 0 }"
        :title="item.userName"
        :value="jobTypeName(item)"
        @click="handleSelectManager(item)"
      ></van-cell>
    </van-action-sheet>
    <van-popup v-model="showPassenger" position="bottom" round>
      <staffCascader @staffChange="changePassenger" />
    </van-popup>

    <van-popup v-model="showApprove" round position="bottom">
      <staffCascader @staffChange="changeApprove" />
    </van-popup>
    <van-popup v-model="showCopy" round position="bottom">
      <staffCascader @staffChange="changeCopy" />
    </van-popup>
  </div>
</template>

<script>
import {
  cartList,
  useApply,
  useApplyInfo,
  editUseApply,
} from "@/api/cart/manage/index.js";
import addImg from "@/assets/images/applyCar/add.jpg";
import pickStaff from "@/components/pickStaff/index.vue";
import { getAllStaffList } from "@/api/staff/index.js";
import { Toast } from "vant";
// import timeLine from "./timeLine.json";
import Schema from "async-validator";
import staffCascader from "./components/staffCascader";
import { queryCardList } from "@/api/car/cardManger.js";
import { getAvailableList } from "@/api/cart/manage/index.js";
import { getSelectAllList, getDepartmentLeader } from "@/api/common/index.js";
export default {
  data() {
    return {
      date: "",
      addImg,
      showManager: false,
      showCarList: false,
      showPassenger: false,
      showApprove: false,
      showCopy: false,
      showStaff: false,
      showTimePicker: false,
      showBeginTime: false,
      showEndTime: false,
      showCardType: false,
      showCardNo: false,

      minDate: new Date(2023, 0, 1),
      maxDate: new Date(2040, 11, 31),
      currentDate: new Date(),
      carList: [],
      carTypeList: [
        {
          value: 1,
          text: "是",
        },
        {
          value: 0,
          text: "否",
        },
      ],
      passengerList: [],
      approveList: [],
      copyList: [],
      staffList: [],
      oilList: [],
      managerUsers: [], //部门负责人
      staffData: [], //员工表
      form: {
        applyDate: "",
        beginTime: "",
        cartCode: "",
        origin: "",
        endTime: "",
        reason: "",
        cardType: "",
        cardNo: "",
        departmentManagers: "",
      },
      platingNumber: "",
      timeList: [],
      beginTime: "",
      endTime: "",
      validator: null,
      options: [],
      cascaderValue: null,
      cardType: "",
      cardName: "",
      rules: {
        cartCode: [
          {
            required: true,
            message: "请选择车辆",
          },
        ],
        applyDate: [
          {
            required: true,
            message: "请选择用车日期",
          },
        ],

        beginTime: [
          {
            required: true,
            message: "请选择开始时间",
          },
        ],
        endTime: [
          {
            required: true,
            message: "请选择预计结束时间",
          },
        ],
        approveUsers: [
          {
            required: true,
            message: "请选择审批人",
          },
        ],
        cardType: [
          {
            required: true,
            message: "请选择是否借用油卡",
          },
        ],
        departmentManagers: [
          { required: true, message: "请配置部门负责人", trigger: "blur" },
        ],
        reason: [
          { required: true, message: "请输入用车事由", trigger: "blur" },
        ],
        origin: [{ required: true, message: "请输入去向", trigger: "blur" }],
      },
    };
  },
  name: "applyCar",
  computed: {
    rulesArr() {
      if (1 == this.form.cardType) {
        return {
          ...this.rules,
          cardNo: [
            {
              required: true,
              message: "请选择油卡",
            },
          ],
        };
      } else {
        return this.rules;
      }
    },
  },
  watch: {
    "form.cartCode"() {
      if (!this.form.applyDate) {
        return;
      }
      getAvailableList({
        date: this.form.applyDate,
        platingNumber: this.platingNumber,
      }).then((res) => {
        this.timeList = res.data[0].timeDetail.map((item) => {
          return {
            ...item,
            disabled: 1 != item.status,
          };
        });

        if (this.form.beginTime) {
          this.beginTime = this.timeList[this.form.beginTime].beginTime;
        }
        if (this.form.beginTime) {
          this.endTime = this.timeList[this.form.endTime].time;
        }
      });
    },
    "form.applyDate"() {
      if (!this.form.applyDate) {
        return;
      }
      getAvailableList({
        date: this.form.applyDate,
        platingNumber: this.platingNumber,
      }).then((res) => {
        this.timeList = res.data[0].timeDetail.map((item) => {
          return {
            ...item,
            disabled: 1 != item.status,
          };
        });

        if (this.form.beginTime) {
          this.beginTime = this.timeList[this.form.beginTime].beginTime;
        }
        if (this.form.beginTime) {
          this.endTime = this.timeList[this.form.endTime].time;
        }
      });
    },
  },
  components: { pickStaff, staffCascader },
  async mounted() {
    this.formatDate();
    this.getList();
    this.getOilList();
    this.getLeaders();
    this.getSelectList();
    await this.getStaffList();

    let applyCode = this.$route.query.applyCode;
    let cartCode = this.$route.query.cartCode;
    let platingNumber = this.$route.query.platingNumber;
    let date = this.$route.query.date;

    if (cartCode) {
      this.form.cartCode = cartCode;
      this.platingNumber = platingNumber;
      this.form.applyDate = date;
    }
    if (applyCode) {
      this.applyCode = applyCode;
      this.getDetail();
    } else {
      this.initApproveUsers();
    }
  },
  filters: {
    formatePassengerList(val) {
      let arr = val.map((item) => {
        return item.userName;
      });
      return arr.join(",");
    },
  },
  methods: {
    jobTypeName(item) {
      if (item.delType == 0) {
        return "已删除";
      } else {
        return item.jobType == 1 ? "在职" : "离职";
      }
    },
    disabledItem(item) {
      return item.jobType == 1 ? false : true;
    },
    getLeaders() {
      getDepartmentLeader().then((res) => {
        let { data, code } = res;
        if (code == 200) {
          let arr = [];
          if (data && data.length > 0) {
            arr.push(data[0]);
            this.form.departmentManagers = arr
              .map((e) => {
                return e.staffCode;
              })
              .toString();
          }
          this.managerUsers = arr;
        }
      });
    },
    getSelectList() {
      getSelectAllList().then((res) => {
        let { data, code } = res;
        if (code == 200) {
          this.staffData = data;
        }
      });
    },
    getList() {
      cartList({
        pageNum: 1,
        pageSize: 99,
      }).then((res) => {
        this.carList = res.data.records;
      });
    },
    getOilList() {
      queryCardList({
        pageNum: 1,
        pageSize: 99,
      }).then((res) => {
        this.oilList = res.data.records;
      });
    },
    //获取员工列表
    async getStaffList() {
      await getAllStaffList({
        type: 0,
      }).then((res) => {
        this.staffList = res.data.map((item) => {
          return {
            ...item,
            text: item.userName,
          };
        });
      });
    },
    initApproveUsers() {
      let approveItem = this.staffList.find((item) => {
        return item.userName == "李昕蔓";
      });
      if (approveItem) {
        this.approveList = [approveItem];
      }

      let copyItem = this.staffList.find((item) => {
        return item.userName == "李昂";
      });
      if (copyItem) {
        this.copyList = [copyItem];
      }
    },

    // 获取用车详情
    getDetail() {
      useApplyInfo({
        applyCode: this.applyCode,
      }).then((res) => {
        let data = res.data;
        this.platingNumber = data.platingNumber;

        this.form.cartCode = data.cartCode;
        this.form.applyDate = data.applyDate;
        this.form.beginTime = data.beginTime;
        this.form.endTime = data.endTime;
        this.form.origin = data.origin;
        this.form.reason = data.reason;

        this.form.cardNo = data.cardNo;
        this.cardName = data.cardVO ? data.cardVO.cardName : "";
        this.form.departmentManagers = data.departmentManagers;
        if (data.departmentManagers) {
          let a = [];
          this.staffData.map((e) => {
            if (e.staffCode == data.departmentManagers) {
              a.push(e);
            }
          });
          this.managerUsers = a;
        }

        this.form.cardType = data.cardType;
        this.cardType = 1 == data.cardType ? "是" : "否";

        this.passengerList = [];

        let passengers = data.passengers;
        let passengersArr;
        if (!passengers) {
          passengersArr = [];
        } else {
          passengersArr = passengers.split(",");
        }
        this.passengerList = passengersArr.map((item, index) => {
          return {
            staffCode: item,
            userName: data.passengersName[index],
          };
        });

        let copyUser = data.copyUser;
        let copyUserArr;
        if (!copyUser) {
          copyUserArr = [];
        } else {
          copyUserArr = copyUser.split(",");
        }
        this.copyList = copyUserArr.map((item, index) => {
          return {
            staffCode: item,
            userName: data.copyUserNames[index],
          };
        });

        this.approveList = [];
        if (data.approveUsers) {
          const approveUsers = data.approveUsers.split(",");
          approveUsers.forEach((e) => {
            let a = this.staffData.find((f) => {
              return f.staffCode === e;
            });
            if (a) {
              this.approveList.push({
                staffCode: a.staffCode,
                userName: a.userName,
              });
            }
          });
        }
      });
    },

    initValidator() {
      this.validator = new Schema(this.rulesArr);
    },
    carListConfirm(e) {
      this.showCarList = false;
      this.platingNumber = e.platingNumber;
      this.form.cartCode = e.bizCode;
    },
    applyDateConfirm(e) {
      this.showTimePicker = false;
      let year = e.getFullYear();
      let month = e.getMonth() + 1;
      let day = e.getDate();

      this.form.applyDate = `${year}-${String(month).padStart(2, "0")}-${String(
        day
      ).padStart(2, "0")}`;
    },
    carNoConfirm(e) {
      this.showCardNo = false;
      this.cardName = e.cardName;
      this.form.cardNo = e.cardNo;
    },
    formatDate() {
      const date = new Date();
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, "0");
      let day = String(date.getDate()).padStart(2, "0");
      this.date = `${year}-${month}-${day}`;
    },

    handleClose() {
      this.showPassenger = false;
      this.showApprove = false;
      this.showCopy = false;
    },

    addPassenger() {
      this.showPassenger = true;
    },
    addManager() {
      this.showManager = true;
    },
    addApprove() {
      this.showApprove = true;
    },
    addCopy() {
      this.showCopy = true;
    },
    beginTimeConfirm(item, index) {
      this.beginTime = item.beginTime;
      this.form.beginTime = index;
      this.showBeginTime = false;
    },
    endTimeConfirm(item, index) {
      this.endTime = item.time;
      this.form.endTime = index;
      this.showEndTime = false;
    },

    carTypeConfirm(item, index) {
      this.form.cardType = item.value;
      this.cardType = item.text;
      this.showCardType = false;
    },
    //删除部门负责人
    delManager(user) {
      this.managerUsers = [];
    },

    delPassenger(item, index) {
      this.passengerList.splice(index, 1);
    },
    delApprove(item, index) {
      this.approveList.splice(index, 1);
    },
    delCopy(item, index) {
      this.copyList.splice(index, 1);
    },

    submit() {
      let passengerArr = this.passengerList.map((item) => {
        return item.staffCode;
      });
      let approveArr = this.approveList.map((item) => {
        return item.staffCode;
      });
      let copyArr = this.copyList.map((item) => {
        return item.staffCode;
      });
      let managerCodeArr = this.managerUsers.map((item) => {
        return item.staffCode;
      });

      let passengers = passengerArr.join(",");
      let approveUsers = approveArr.join(",");
      let copyUser = copyArr.join(",");
      let departmentManagers = managerCodeArr.join(",");
      let cardNo = 1 == this.form.cardType ? this.form.cardNo : "";

      let postData = {
        ...this.form,
        passengers,
        approveUsers,
        copyUser,
        cardNo,
        departmentManagers,
      };
      this.initValidator();

      this.validator
        .validate(postData)
        .then((res) => {
          let editType = this.$route.query.editType;
          if (!editType) {
            useApply(postData).then((res) => {
              Toast("预约成功");
              this.$router.go(-1);
            });
          } else {
            editUseApply({
              ...postData,
              applyCode: this.applyCode,
            }).then((res) => {
              Toast("预约成功");
              this.$router.go(-1);
            });
          }
        })
        .catch((e) => {
          Toast(e.errors[0].message);
        });
    },

    changePassenger(item) {
      let indexItem = this.passengerList.findIndex((listItem) => {
        return item.departmentCode == listItem.staffCode;
      });
      if (indexItem > -1) {
        Toast("不可以重复添加");
        return;
      }
      this.showPassenger = false;
      this.passengerList.push({
        staffCode: item.departmentCode,
        userName: item.departmentName,
      });
    },
    handleSelectManager(item) {
      if (item.delType == 0) {
        return;
      } else {
        if (item.jobType == 1) {
          this.managerUsers = [item];
          this.showManager = false;
        } else {
          return;
        }
      }
    },
    changeApprove(item) {
      let indexItem = this.approveList.findIndex((listItem) => {
        return item.departmentCode == listItem.staffCode;
      });
      if (indexItem > -1) {
        Toast("不可以重复添加");
        return;
      }
      this.showApprove = false;
      this.approveList.unshift({
        staffCode: item.departmentCode,
        userName: item.departmentName,
      });
    },

    changeCopy(item) {
      let indexItem = this.copyList.findIndex((listItem) => {
        return item.departmentCode == listItem.staffCode;
      });
      if (indexItem > -1) {
        Toast("不可以重复添加");
        return;
      }
      this.showCopy = false;
      this.copyList.push({
        staffCode: item.departmentCode,
        userName: item.departmentName,
      });
    },
    showBeginTimeDialog() {
      if (!this.form.cartCode || !this.form.applyDate) {
        Toast("请先选择车辆和用车日期");
        return;
      }
      this.showBeginTime = true;
    },
    showEndTimeDialog() {
      if (!this.form.cartCode || !this.form.applyDate) {
        Toast("请先选择车辆和用车日期");
        return;
      }
      this.showEndTime = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.applyCar {
  min-height: 100%;
  background: #fafafa;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.card {
  background: #fff;
  padding: 0.2rem 0.3rem;
  margin-top: 0.2rem;
  .sub-title {
    color: #646566;
    font-size: 14px;
    margin-bottom: 0.2rem;
  }
  .star::before {
    position: absolute;
    left: 8px;
    color: #ee0a24;
    font-size: 14px;
    content: "*";
  }

  .tag-ul {
    margin-top: 0.2rem;
    margin-bottom: 0.3rem;
    .van-tag {
      margin-right: 0.3rem;
    }
  }

  .text-area {
    background: #f6fafe;
    border-radius: 0.1rem;
    width: 100%;
    padding: 0.2rem;
  }

  .add-img {
    height: 1rem;
    width: 1rem;
  }
}

.bottom {
  padding: 0.2rem 0.3rem;
  .button {
    width: 6.9rem;
    height: 0.8rem;
    background: #0094ee;
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 0.26rem;
    color: #ffffff;
    line-height: 0.37rem;
  }
}
.disabledName {
  color: #c0c4cc;
  cursor: not-allowed;
}
</style>
