<!--  -->
<template>
  <div class="staff-cascader">
    <van-cascader
      v-model="cascaderValue"
      title="请选择员工"
      :options="options"
      @close="showApprove = false"
      @change="onChange"
      @finish="onFinish"
      :field-names="fieldNames"
    />
  </div>
</template>

<script>
import { getList } from "@/api/department/index.js";
export default {
  data() {
    return {
      cascaderValue: null,
      options: [],
      showApprove: false,
      fieldNames: {
        text: "departmentName",
        value: "departmentCode",
        children: "children",
      },
    };
  },
  name: "staffCascader",
  computed: {},
  mounted() {
    this.loadNode();
  },
  filters: {},
  methods: {
    loadNode() {
      getList().then((res) => {
        this.options = res.data.map((item) => {
          if (0 == item.type) {
            return {
              ...item,
              children: [],
            };
          } else {
            return item;
          }
        });
      });
    },
    onChange(item) {
      let length = item.selectedOptions.length;
      if (1 == item.selectedOptions[length - 1].type) {
        return;
      }
      getList({ departmentCode: item.value }).then((res) => {
        item.selectedOptions[length - 1].children = res.data.map((item) => {
          if (0 == item.type) {
            return {
              ...item,
              children: [],
            };
          } else {
            return item;
          }
        });
      });
    },
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish(item) {
      this.usrshow = false;
      let length = item.selectedOptions.length;
      let result = item.selectedOptions[length - 1];
      this.$emit("staffChange", result);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
