
<template>
  <div class="pickStaff-contain">
    <el-tree
      :props="props"
      :load="loadNode"
      lazy
      check-strictly
      show-checkbox
      ref="tree"
    >
    </el-tree>
    <div class="bottom-buttons">
      <div class="button cancel" @click="cancel">取消</div>
      <div class="button primary" @click="confirm">确定</div>
    </div>
  </div>
</template>

<script>
import { getList } from "@/api/department/index.js";
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      props: {
        label: "departmentName",
        children: "children",
        isLeaf: (data, node) => {
          if (!node.data) {
            return false;
          }
          return !!node.data.staffDuty;
        },
      },
    };
  },
  props: {
    checkNodes: {
      require: true,
    },
  },
  mounted() {},
  methods: {
    loadNode(node, resolve) {
      if (node.level === 0) {
        getList({
          departmentCode: "",
        }).then((res) => {
          let list = res.data;
          return resolve(list);
        });
      }
      if (node.level > 0) {
        getList({
          departmentCode: node.data.departmentCode,
        }).then((res) => {
          let list = res.data;
          return resolve(list);
        });
      }
    },
    cancel() {
      this.$emit("close");
    },
    confirm() {
      let checkNodes = JSON.parse(
        JSON.stringify(this.$refs.tree.getCheckedNodes())
      );

      this.$emit("update:checkNodes", checkNodes);
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.pickStaff-contain {
  background: #fff;
  padding-top: 0.2rem;
  height: 100%;
  overflow-y: scroll;
  .el-tree {
    margin-bottom: 1.4rem;
  }
  .bottom-buttons {
    background: #fff;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0.2rem;
    .button {
      width: 2.4rem;
      height: 0.8rem;
      border-radius: 0.4rem;
      font-size: 0.26rem;
      line-height: 0.37rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .cancel {
      background: rgba(0, 148, 238, 0.1);
      color: #0094ee;
      margin-right: 0.4rem;
    }
    .primary {
      background: #0094ee;
      color: #ffffff;
    }
  }
}
</style>
